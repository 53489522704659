import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 626.000000 626.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,626.000000) scale(0.100000,-0.100000)">
<path d="M0 3130 l0 -3130 3130 0 3130 0 0 3130 0 3130 -3130 0 -3130 0 0
-3130z m1562 683 c14 -16 418 -816 418 -828 0 -11 -119 -3 -195 13 l-80 17
-77 155 c-42 85 -77 150 -77 145 -2 -30 -33 -240 -36 -243 -2 -2 -115 37 -123
43 -6 5 107 674 117 694 14 26 33 27 53 4z m1102 -5 c18 -30 111 -657 98 -670
-11 -11 -187 -78 -205 -78 -10 0 -17 32 -26 110 -22 188 -16 188 -110 2 -88
-175 -87 -174 -178 -187 l-53 -7 200 408 c110 224 207 416 216 426 23 24 41
23 58 -4z m864 -22 c15 -24 63 -116 107 -205 76 -153 81 -162 105 -157 245 54
380 68 542 59 l103 -6 74 156 c75 158 107 204 136 194 23 -10 30 -36 60 -237
l28 -186 41 -13 c73 -22 218 -90 328 -153 59 -34 106 -63 105 -64 -1 -2 -63
20 -137 47 -136 51 -310 102 -318 94 -4 -4 74 -542 101 -694 19 -108 64 -148
169 -150 40 -1 59 -18 37 -33 -18 -12 -437 1 -462 14 -17 9 -17 11 -3 27 9 10
16 34 16 53 0 34 -97 773 -104 793 -2 6 -101 -186 -220 -426 -119 -240 -225
-444 -237 -455 -21 -19 -21 -18 -48 12 -25 28 -236 418 -276 512 -10 23 -21
42 -25 42 -9 0 -162 -63 -181 -75 -13 -8 -22 -49 -38 -172 -11 -89 -21 -184
-21 -212 0 -59 12 -69 91 -78 51 -6 71 -19 49 -33 -7 -4 -110 -7 -229 -7 -185
2 -216 4 -216 17 0 10 14 17 48 20 65 8 84 31 102 121 26 131 42 249 34 249
-4 0 -70 -31 -146 -69 -138 -68 -261 -121 -283 -121 -8 0 -10 -9 -6 -27 29
-120 41 -131 145 -148 46 -8 66 -16 66 -26 0 -12 -33 -14 -215 -11 -118 1
-225 5 -237 7 -22 5 -33 35 -14 35 15 0 21 30 15 72 l-7 41 -68 -7 c-71 -6
-254 8 -292 24 -16 6 -1 9 55 9 164 2 355 42 534 114 60 25 208 94 329 156
310 157 464 214 650 241 82 11 252 12 310 1 l40 -7 -60 -8 c-150 -18 -215 -30
-214 -40 0 -6 33 -76 73 -156 l73 -145 142 289 c77 159 141 292 141 296 0 11
-172 1 -280 -16 -115 -18 -287 -63 -395 -104 -44 -16 -81 -30 -82 -30 -2 0
-14 23 -29 51 -14 28 -28 49 -30 46 -3 -2 -8 -32 -12 -66 l-7 -61 -150 -66
c-437 -191 -547 -234 -699 -279 -103 -29 -304 -65 -369 -65 l-47 0 -82 -162
c-66 -130 -88 -164 -105 -166 -27 -4 -45 22 -152 225 l-75 140 -65 17 c-36 10
-103 33 -150 52 -46 18 -86 33 -87 31 -6 -5 -44 -323 -44 -364 0 -49 6 -54 85
-68 41 -8 60 -16 60 -26 0 -12 -35 -14 -225 -14 -193 0 -225 2 -225 15 0 10
15 17 49 22 66 9 84 30 101 119 22 109 64 383 59 390 -2 3 -42 30 -89 59 -87
53 -110 78 -37 41 73 -37 243 -101 346 -131 135 -38 199 -50 335 -64 l113 -11
25 -48 c14 -26 28 -47 32 -47 4 0 18 22 31 49 23 50 23 50 79 56 196 21 440
93 706 208 325 141 448 196 456 204 5 5 20 80 34 168 14 88 32 200 40 248 21
130 45 149 93 73z"/>
</g>
</svg>

    </Box> 
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
